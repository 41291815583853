const Movies = [
    {
        id: 1,
        Image: "https://i.pinimg.com/564x/50/91/4d/50914d89e401bc3c0041a64648c17ce5.jpg",
        Title: "Star Wars",
        Subtitle: "Return Of The Jedi",
        ReleaseDate: "Nov.02.1999",
        Director: "George Lucas",
        MainCast: "Harrison Ford, Mark Hamil, Carey Fisher",
        Description: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Asperiores, ipsa.",
        Type: "Action, Adventure, Sci-Fi",
        Price: "", 
    },
    {
        id: 2,
        Image: "https://i.pinimg.com/564x/b7/3c/ea/b73cea09ce9f787334a66a92ef1d5806.jpg",
        Title: "The Hateful Eight",
        Subtitle: "Subtitle",
        ReleaseDate: "Dec.08.2016",
        Director: "Quentin Tarantino",
        MainCast: "Sammuel L Jackson",
        Description: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Asperiores, ipsa.",
        Type: "Action, Adventure, Western",
        Price: "",
    },
    {
        id: 3,
        Image: "https://i.pinimg.com/564x/de/40/96/de4096d46dc41da1fd9d441cb8fcb01e.jpg",
        Title: "Full Metal Jacket",
        Subtitle: "Subtitle",
        ReleaseDate: "Jul.22.1975",
        Director: "Stanley Cubric",
        MainCast: "",
        Description: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Asperiores, ipsa.",
        Type: "Action, Adventure, War",
        Price: "",
    },
    {
        id: 4,
        Image: "https://i.pinimg.com/564x/3d/78/90/3d789090131a6c9082399a1084f94ecb.jpg",
        Title: "Blade Runner",
        Subtitle: "Subtitle",
        ReleaseDate: "May.09.1970",
        Director: "",
        MainCast: "Harrison Ford",
        Description: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Asperiores, ipsa.",
        Type: "Action, Adventure, Sci-Fi",
        Price: "",
    },
    {
        id: 5,
        Image: "https://i.pinimg.com/564x/29/7d/66/297d66e71f2d7a9bcfb4b501b9230135.jpg",
        Title: "Fight Club",
        Subtitle: "Subtitle",
        ReleaseDate: "",
        Director: "David Fincher",
        MainCast: "Brad Pit",
        Description: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Asperiores, ipsa.",
        Type: "Action, Psychological",
        Price: "",
    },
    {
        id: 6,
        Image: "https://i.pinimg.com/236x/59/06/92/5906920d40cceade9cf0ef5f18ce807d.jpg",
        Title: "The Lighhouse",
        Subtitle: "Subtitle",
        ReleaseDate: "March.06.2017",
        Director: "",
        MainCast: "William Defoe, Robert Patterson",
        Description: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Asperiores, ipsa.",
        Type: "Action, Adventure, Sci-Fi",
        Price: "",
    },
    {
        id: 7,
        Image: "https://i.pinimg.com/564x/da/69/e1/da69e1fb92c8d41f70e1b8274e30cf7f.jpg",
        Title: "American Psycho",
        Subtitle: "Subtitle",
        ReleaseDate: "",
        Director: "",
        MainCast: "Christian Bale",
        Description: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Asperiores, ipsa.",
        Type: "Action, Adventure, Sci-Fi",
        Price: "",
    },
    {
        id: 8,
        Image: "https://i.pinimg.com/236x/4f/01/ef/4f01ef5770b7b97fd9752d3f9ca86c5e.jpg",
        Title: "Leon",
        Subtitle: "Subtitle",
        ReleaseDate: "",
        Director: "",
        MainCast: "Natalie Portman",
        Description: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Asperiores, ipsa.",
        Type: "Action, Adventure, Sci-Fi",
        Price: "",
    },
    {
        id: 9,
        Image: "https://i.pinimg.com/236x/a5/b9/e1/a5b9e1f7b3b04f3b4fe35ddb449aff01.jpg",
        Title: "SE7EN",
        Subtitle: "Subtitle",
        ReleaseDate: "",
        Director: "David Fincher",
        MainCast: "Brad Pit, Morgan Freeman",
        Description: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Asperiores, ipsa.",
        Type: "Action, Adventure, Sci-Fi",
        Price: "",
    },
    {
        id: 10,
        Image: "https://i.pinimg.com/736x/6b/4b/81/6b4b811eee49fdd92831b69486f0889c.jpg",
        Title: "Jaws",
        Subtitle: "Subtitle",
        ReleaseDate: "",
        Director: "Steven Spielberg",
        MainCast: "",
        Description: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Asperiores, ipsa.",
        Type: "Action, Adventure, Sci-Fi",
        Price: "",
    },
]

export default Movies