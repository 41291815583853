import React, {useState} from 'react'
import { motion } from 'framer-motion';
import Aos from 'aos';

import "./Styles/Landing.css"

import Movies from '../../Componets/API/Movies/Movies';

const Landing = () => {

    const [movieData, setMovieData] = useState(Movies);
    const [searchMovie, setSearchMovie] = useState("")
    console.log(searchMovie)

  return (
    <>
        <input type="text" placeholder='Search Movie...' onChange={(e) => setSearchMovie(e.target.value)} />
        <div className="Movie-Container">
            {Movies.filter((film) => {
                    return searchMovie.toLowerCase() === "" ? film : film.Title.toLowerCase().includes(searchMovie);
                })
            .map((films) => {
                const {id, Title, Subtitle, Director, ReleaseDate, MainCast, Description, Type, Image} = films;

                // const filter = (filmType) => {
                //     const result = Movies.filter((CurrentFilm) => {
                //         return CurrentFilm.Type === filmType;
                //     })
                //     setMovieData(result)
                // }

                return (
                    <>
                        <div className="Movie-Card" key={id}>
                            <img src={Image} alt="" className='Movie-Image'/>

                            <div className="Movie-Detail-Card">
                                <h3 className="Movie-Title">
                                    {Title}
                                </h3>
                                <motion.h3 className="Movie-Subtitle"

                                >
                                    {Subtitle}
                                </motion.h3>

                                <div className="Film-Detail-Box">
                                    <p className="Film-Detail"><span id='Realease'>Release Date</span>: {ReleaseDate}</p>
                                    <p className="Film-Detail"><span id='Director'>Director</span>: {Director}</p>
                                    <p className="Film-Detail"><span id='Main-Cast'>Main Cast</span>: {MainCast}</p>
                                </div>

                                <div className="Film-Overview-Box">
                                    <p className="Film-Overview">
                                        {Description}
                                    </p>
                                    <p className="Film-Overview">
                                        {Description}
                                    </p>
                                </div>

                                <div className="Type-Box">
                                    <p className="Film-Type">
                                        {Type}
                                    </p>
                                </div>

                                <motion.button className="Watch-Now-Button"
                                    whileTap={{scale: .9, ease: "easeInOut"}}
                                >
                                    <p className="Button-Text">
                                        Watch Now <span id='Button-Exclemation'>!</span>
                                    </p>
                                </motion.button>

                            </div>
                        </div>
                    </>
                )
            })}
        </div>
    </>
  )
}

export default Landing